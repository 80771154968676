import React from 'react';
import { Link } from 'gatsby';
import { Link as GoLink } from 'react-scroll/modules';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/Image';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';

const MoviePage = () => (
  <Layout page="movie-page">
    <Seo title="MOVIE" />
    <section className="page-head">
      <ListBrand />
      <ListLink />
      <h2 className="main-title">
        <span>MOVIE</span>
      </h2>
    </section>
    <section className="page-content flex-layout">
      <div className="main-content">
        <div className="content-block" id="movie">
          <h3 className="sub-title">
            <span>ムービー紹介</span>
          </h3>
        </div>
        <div className="content-block left-end md:py-16 py-8 md:pr-20 pr-5">
          <ul className="movie-list">
            {/* 動画一覧ページの動画掲載数は「最新の10件」。動画を追加する場合は「一番古い動画を削除して、一番上に動画を追加する」 */}
            <li>
              <div className="youtube-wrap mb-4">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/EOhCScolrb8"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className="text-white">グリル'17 使用動画2</p>
            </li>
            <li>
              <div className="youtube-wrap mb-4">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/4VMEsRjV6pk"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className="text-white">グリル'17使用動画</p>
            </li>
            <li>
              <div className="youtube-wrap mb-4">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/zSY5Fs0_IJA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className="text-white">アウトドアオーブン使用動画18</p>
            </li>
            <li>
              <div className="youtube-wrap mb-4">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/HX6fywjSuKU"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className="text-white">アウトドアオーブン使用動画17</p>
            </li>
            <li>
              <div className="youtube-wrap mb-4">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/CrYhhzFLwXA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className="text-white">
                薪ストーブ動画集(6140CB・3142CB・5660CB)
              </p>
            </li>
            <li>
              <div className="youtube-wrap mb-4">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/vHTp7H2GLBQ"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className="text-white">アウトドアオーブン メンテナンス動画</p>
            </li>
            <li>
              <div className="youtube-wrap mb-4">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/tpv1FLWobXI"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className="text-white">グリルフォーノ使用動画2</p>
            </li>
            <li>
              <div className="youtube-wrap mb-4">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Dg8Ye9KgdRc"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className="text-white">グリルフォーノ使用動画</p>
            </li>
            <li>
              <div className="youtube-wrap mb-4">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Cu7uwIg7wVg"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className="text-white">アウトドアオーブン使用動画16</p>
            </li>
            <li>
              <div className="youtube-wrap mb-4">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/JLjz9gKnoxY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className="text-white">アウトドアオーブン使用動画15</p>
            </li>
          </ul>
        </div>
        <div className="content-block">
          <a
            className="btn red full mt-5"
            href="https://www.youtube.com/@morsoemoments"
            target="_blank"
            rel="noreferrer"
          >
            Youtubeチャンネルはこちら
          </a>
          <Link className="btn full white mt-5" to="/works/" id="works">
            導入事例はこちら
          </Link>
          <a
            className="btn pdf full mt-5"
            href="/update/pdf/andersen.pdf"
            target="_blank"
            id="catalog"
          >
            PDFカタログ ダウンロード
          </a>
        </div>
      </div>
      <div className="side-list">
        <ul className="sticky">
          <li>
            <GoLink className="dot" to="movie" spy smooth duration={800}>
              ムービー紹介
            </GoLink>
          </li>
          <li>
            <GoLink className="c-yellow" to="works" spy smooth duration={800}>
              導入事例
            </GoLink>
          </li>
          <li>
            <GoLink to="catalog" spy smooth duration={800} className="pdf">
              PDFカタログ
            </GoLink>
          </li>
        </ul>
      </div>
    </section>

    <section className="bread">
      <ul>
        <li>
          <Link to="/">
            <Image filename="ico/home.png" />
          </Link>
        </li>
        <li>ムービー紹介</li>
      </ul>
    </section>
  </Layout>
);

export default MoviePage;
